import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import InterconButton from '../components/Buttons/InterconButton/index';
import UserMiddleware from '../middleware/user';
import Cryptography from '../services/cryptography';

const MySwal = withReactContent(Swal);

const Login = ({ redirect }) => {
  const user = UserMiddleware();
  const { login } = user;

  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [passwordShow, setPasswordShow] = useState(false);
  const handlePasswordIconClick = () => setPasswordShow(!passwordShow);

  const verifyValidEmail = async (email) => {
    try {
      const url = `${process.env.NEXT_PUBLIC_API_URL}/users?email=${encodeURIComponent(email)}`;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Erro ao obter informações do usuário');
      }

      const userInfo = await response.json();
      return userInfo;
    } catch (error) {
      console.error('Erro ao obter informações do usuário:', error);
      throw error;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      if (email === '' || password === '') {
        setIsLoading(false);
        MySwal.fire({
          icon: 'error',
          title: 'Opa...',
          html: 'Preencha os campos de Email e Senha corretamente.'
        });
      } else {
        const user = await login(email, password);
        // eslint-disable-next-line no-underscore-dangle
        if (mixpanel.__loaded) {
          mixpanel?.people.set({ name: user?.id, email });
          mixpanel?.identify(email);
          mixpanel?.track('login', { ...user });
        }

        if (user.user && user.userType) {
          if (redirect !== null) {
            router.push(redirect);
          } else if (user?.userType?.type === 'company') {
            console.log(
              'ENTROU AQUI',
              new Date(user?.userType?.createdAt) > new Date('2024-06-12T17:08:28.704Z')
            );
            if (new Date(user?.userType?.createdAt) > new Date('2024-06-12T17:08:28.704Z')) {
              router.push('/empresa/busca');
            } else {
              router.push('/empresa/minhas-vagas/ativas');
            }
          } else if (user?.userType?.type === 'recruiter') {
            router.push('/recrutador/minhas-vagas/ativas');
          } else {
            throw new Error();
          }

          setIsLoading(false);
        } else {
          const checkingValidEmail = await verifyValidEmail(email);
          console.log(checkingValidEmail);
          let errorMessage = 'Por favor verifique suas credenciais.';
          let titleMessage = 'E-mail ou senha incorretos!';
          if (checkingValidEmail?.length === 0) {
            titleMessage = 'E-mail incorreto!';
            errorMessage = 'Revise o email digitado';
          }

          if (checkingValidEmail?.length > 0) {
            titleMessage = 'Senha incorreta!';
            errorMessage = 'Revise a senha digitada';
          }

          setIsLoading(false);
          MySwal.fire({
            icon: 'error',
            title: titleMessage,
            html: errorMessage
          });
        }
      }
    } catch (error) {
      console.error('erro', error);
      setIsLoading(false);
      MySwal.fire({
        icon: 'error',
        title: 'Algo deu errado...',
        html: 'Aconteceu um erro inesperado do lado do servidor. Tente novamente mais tarde.'
      });
    }
  };

  return (
    <Flex
      minH="100vh"
      align="center"
      justify="center"
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack spacing={8} mx="auto" maxW="lg" py={12} px={6}>
        <Stack align="center">
          <Image src="/logo-sorriso.png" width="8vw" alt="logo" mb="2rem" />
          <Heading fontSize="2xl" align="center">
            Que bom ver você aqui!
            <Text fontSize={18} mt="2rem">
              Faça seu login e recrute com a Huntly
            </Text>
          </Heading>
        </Stack>
        <Box rounded="lg" bg={useColorModeValue('white', 'gray.700')} boxShadow="lg" p={8}>
          <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <FormControl id="email">
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={email}
                  data-test="login-email"
                  onChange={(event) => setEmail(event.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Senha</FormLabel>
                <InputGroup size="md">
                  <Input
                    id="password"
                    data-test="login-password"
                    type={passwordShow ? 'text' : 'password'}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <InputRightElement id="show-hide-password">
                    <IconButton
                      aria-label="Hide/Show Password"
                      icon={passwordShow ? <ViewIcon /> : <ViewOffIcon />}
                      onClick={handlePasswordIconClick}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Stack spacing={10}>
                <Button
                  type="submit"
                  data-test="login-submit-button"
                  isLoading={isLoading}
                  loadingText="Entrando..."
                  bg="#F25252"
                  color="white"
                  _hover={{
                    bg: '#BF4141'
                  }}
                >
                  Entrar
                </Button>
                <Stack direction="row" justifyContent="space-between">
                  <InterconButton color="#F25252" />
                  <Link href="/recuperar-senha">
                    <Text cursor="pointer">Esqueci minha senha</Text>
                  </Link>
                </Stack>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

export default Login;

export const getServerSideProps = async (ctx) => {
  if (ctx.req?.cookies?.hh_user && ctx.req?.cookies?.hh_userType && ctx.req?.cookies?.hh_token) {
    const userType = JSON.parse(Cryptography.decrypt(ctx.req?.cookies?.hh_userType));
    let destination = 'empresa/minhas-vagas/ativas';

    if (userType?.type !== 'company') {
      destination = 'recrutador';
    }

    return {
      redirect: {
        permanent: false,
        destination
      }
    };
  }

  const { redirect } = ctx.query;

  return {
    props: {
      redirect: redirect || null
    }
  };
};
