import { Button } from '@chakra-ui/react';
import { BsHeadset, BsLifePreserver } from 'react-icons/bs';

const InterconButton = ({ color, size = 'sm', isNewVersion = false }) => {
  return (
    <Button
      size={size}
      marginRight="10px"
      transition="0.5s ease"
      background="transparent"
      id="open_intercon_trigger"
      border="1px solid rgba(75, 75, 75, 0.2)"
      width={isNewVersion ? { sm: '100%', md: 'fit-content' } : {}}
      leftIcon={isNewVersion ? <BsLifePreserver /> : <BsHeadset />}
      _hover={{ color, background: 'transparent', borderColor: color }}
    >
      {isNewVersion ? 'Precisa de ajuda?' : 'Ajuda'}
    </Button>
  );
};

export default InterconButton;
